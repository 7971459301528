/*eslint no-magic-numbers: "off"*/
import React from 'react'
import './App.css'
import 'typeface-roboto'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'

import moment from 'moment'

const KM2MILE = 1.60934

function asFloat(str) {
  try {
    return parseFloat(str)
  } catch {
    return 0.00
  }
}

const timeFormat = seconds => {
  const date = moment(seconds * 1000)

  return date.isValid() ?  date.utc().format('HH:mm:ss') : ''
}
const fix = distance => distance.toFixed(2)

export function seconds(str) {

  const parts = String(str).split(':')

  switch (parts.length) {
  case 1:
    return parts[0] * 60
  case 2:
    return +parts[0] * 60 + +parts[1]
  case 3:
    return +parts[0] * 60 * 60 + +parts[1] * 60 + +parts[2]
  default:
    return null
  }
}

const match = distance => String(distance).match(/^(\d+)$/)

export function dist(distance, unit) {
  switch (unit) {
  case 'M':
    return +distance  * KM2MILE
  case 'm':
    return +distance / 1000
  default:
    return +distance
  }
}

export function calc(seconds, kms) {
  const perKm = seconds / kms
  const perMile = perKm * KM2MILE
  const kmh = 3600 / seconds * kms
  const mph = 3600 / seconds * kms / KM2MILE

  const result = [
    { km: fix(kmh)          , mile: fix(mph)               , time: timeFormat(3600)            , description: 'distance per hour' } ,
    { km: fix(0.400)        , mile: fix(0.400  / KM2MILE)  , time: timeFormat(perKm * 0.400)   , description: '400 meter' }       ,
    { km: fix(1)            , mile: fix(1  / KM2MILE)      , time: timeFormat(perKm)           , description: 'pace per km' }       ,
    { km: fix(5)            , mile: fix(5  / KM2MILE)      , time: timeFormat(perKm * 5)       , description: '5k' }                ,
    { km: fix(10)           , mile: fix(10 / KM2MILE)      , time: timeFormat(perKm * 10)      , description: '10k' }               ,
    { km: fix(10.55)        , mile: fix(10.55 / KM2MILE)   , time: timeFormat(perKm * 10.55)   , description: 'quarter marathon' }  ,
    { km: fix(15)           , mile: fix(15 / KM2MILE)      , time: timeFormat(perKm * 15)      , description: '15k' }               ,
    { km: fix(20)           , mile: fix(20 / KM2MILE)      , time: timeFormat(perKm * 20)      , description: '20k' }               ,
    { km: fix(21.0975)      , mile: fix(21.0975 / KM2MILE) , time: timeFormat(perKm * 21.0975) , description: 'half marathon' }     ,
    { km: '42.195'          , mile: fix(42.195 / KM2MILE)  , time: timeFormat(perKm * 42.195)  , description: 'marathon' }          ,

    { km: fix(1 * KM2MILE)  , mile: fix(1)                 , time: timeFormat(perMile)         , description: 'pace per mile' }     ,
    { km: fix(4 * KM2MILE)  , mile: fix(4)                 , time: timeFormat(perMile * 4)     , description: '4 miles' }           ,
    { km: fix(6 * KM2MILE)  , mile: fix(6)                 , time: timeFormat(perMile * 6)     , description: '6 miles' }           ,
    { km: fix(10 * KM2MILE) , mile: fix(10)                , time: timeFormat(perMile * 10)    , description: '10 miles' }          ,
  ]

  return result
}

export function calcTime(pace, seconds) {
  console.log({ pace, seconds })
  const perKm = pace
  const perMile = perKm * KM2MILE
  const kms = seconds / perKm
  const kmh = 3600 / seconds * kms
  const mph = 3600 / seconds * kms / KM2MILE

  const result = [
    { km: fix(kmh)          , mile: fix(mph)               , time: timeFormat(3600)            , description: 'distance per hour' } ,
    { km: fix(1)            , mile: fix(1  / KM2MILE)      , time: timeFormat(perKm)           , description: 'pace per km' }       ,
    { km: fix(5)            , mile: fix(5  / KM2MILE)      , time: timeFormat(perKm * 5)       , description: '5k' }                ,
    { km: fix(10)           , mile: fix(10 / KM2MILE)      , time: timeFormat(perKm * 10)      , description: '10k' }               ,
    { km: fix(10.55)        , mile: fix(10.55 / KM2MILE)   , time: timeFormat(perKm * 10.55)   , description: 'quarter marathon' }  ,
    { km: fix(15)           , mile: fix(15 / KM2MILE)      , time: timeFormat(perKm * 15)      , description: '15k' }               ,
    { km: fix(20)           , mile: fix(20 / KM2MILE)      , time: timeFormat(perKm * 20)      , description: '20k' }               ,
    { km: fix(21.0975)      , mile: fix(21.0975 / KM2MILE) , time: timeFormat(perKm * 21.0975) , description: 'half marathon' }     ,
    { km: '42.195'          , mile: fix(42.195 / KM2MILE)  , time: timeFormat(perKm * 42.195)  , description: 'marathon' }          ,

    { km: fix(1 * KM2MILE)  , mile: fix(1)                 , time: timeFormat(perMile)         , description: 'pace per mile' }     ,
    { km: fix(4 * KM2MILE)  , mile: fix(4)                 , time: timeFormat(perMile * 4)     , description: '4 miles' }           ,
    { km: fix(6 * KM2MILE)  , mile: fix(6)                 , time: timeFormat(perMile * 6)     , description: '6 miles' }           ,
    { km: fix(10 * KM2MILE) , mile: fix(10)                , time: timeFormat(perMile * 10)    , description: '10 miles' }          ,
    { km: fix(kms)          , mile: fix(kms / KM2MILE)     , time: timeFormat(seconds)         , description: 'distance' }          ,
  ]

  return result
}


/** event to value */
const e2v = fn => e => fn(e.target.value)

class App extends React.Component {
  state = {
    pace: '5:20',
    paceValid: true,
    distance: '1',
    distanceValid: true,
    unit: 'k',
    time: '1:00:00',
    timeValid: true,
    rows: [],
  }

  componentDidMount() {
    this.recalculate()
  }

  changePace = pace => {
    const paceValid = seconds(pace) !== null

    this.setState({  pace, paceValid }, this.recalculate)
  }

  changeDistance = distance => {
    const distanceValid = match(distance) !== null

    this.setState({ distance, distanceValid }, this.recalculate)
  }

  changeTime = time => {
    const timeValid = seconds(time) !== null

    this.setState({ time, timeValid }, this.recalculate)
  }


  changeUnit = unit => this.setState({ unit }, this.recalculate)

  recalculate = () => {
    const { pace, distance, time, unit } = this.state

    if (unit === 'T') {
      this.setState({ rows: calcTime(seconds(pace), seconds(time)) })
    } else {
      this.setState({ rows: calc(seconds(pace), dist(distance, unit)) })
    }
  }

  render() {
    console.table(this.state.rows)

    return (
      <React.Fragment>
        <Container component="main" maxWidth="sm">
          <Typography component="h1" variant="h5">
          Pace Calculator
          </Typography>

          <form noValidate>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  error={ !this.state.paceValid }
                  required
                  variant="outlined"
                  id="pace"
                  label="pace"
                  value={this.state.pace}
                  onChange={e2v(this.changePace)}
                  autoFocus
                />
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <TextField
                      error={ !this.state.distanceValid }
                      required
                      variant="outlined"
                      id="distance"
                      label="distance"
                      value={this.state.distance}
                      onChange={e2v(this.changeDistance)}
                      autoFocus
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={ !this.state.timeValid }
                      variant="outlined"
                      id="time"
                      label="time"
                      value={this.state.time}
                      onChange={e2v(this.changeTime)}
                      autoFocus
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <RadioGroup value={this.state.unit} onChange={e2v(this.changeUnit)}>
                  <FormControlLabel style={{ width: 18, height: 30 }} value="k" control={<Radio />} label="kilometers" />
                  <FormControlLabel style={{ width: 18, height: 30 }} value="m" control={<Radio />} label="meters" />
                  <FormControlLabel style={{ width: 18, height: 30 }} value="M" control={<Radio />} label="miles" />
                  <FormControlLabel style={{ width: 18, height: 30 }} value="T" control={<Radio />} label="time" />
                </RadioGroup>
              </Grid>
            </Grid>
          </form>

          <Paper>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell              >Description</TableCell>
                  <TableCell align="right">Time</TableCell>
                  <TableCell align="right">Kilometers</TableCell>
                  <TableCell align="right">Miles</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {this.state.rows.sort((a,b) => asFloat(a.km) - asFloat(b.km)).map(({ km, mile, time, description }) =>
                  <TableRow key={description}>
                    <TableCell >{ description }</TableCell>
                    <TableCell align="right">{ time }</TableCell>
                    <TableCell align="right">{ km }</TableCell>
                    <TableCell align="right">{ mile }</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Paper>
        </Container>
      </React.Fragment>
    )
  }
}

export default App
